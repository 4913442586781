import React, { useRef, useState, useEffect } from 'react';

function LanguageSelector({ onChange , pclass=''}) {
    const dropdownRef = useRef();
    const [selectedLanguageId, setSelectedLanguageId] = useState('en')
    const [showDropDown, setShowdropdown] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const LanguageList = [
        {id: 'en', name: 'English'},
        {id: 'fr', name: 'French'},
        {id: 'es', name: 'Spanish'}
    ]

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        onChange(newLanguage);
    };

    const outsideFilterClick = (event) => {
        if (dropdownRef.current) {
            if (dropdownRef.current.contains(event.target)) {
                // inside click
                return;
            }
            // outside click
        
        }
        setShowdropdown(false)

    }

    useEffect(() => {
        document.addEventListener('click', outsideFilterClick);
        return () => {
            document.removeEventListener('click', outsideFilterClick);
        };
    }, [])

  return (
    <div className={`dropdown_input_wrapper_footer ${pclass}`} onClick={()=>{setShowdropdown(false)}} ref={dropdownRef}>
        <span className={`drop_down_icn_left ${pclass}`}></span>       
        <input
            type="text"
            placeholder="Select Language"
            id="voice_id"
            name="voice_id"
            readOnly={true}
            value={selectedLanguage}
            className={`cte_input_title_dropdown ${pclass}`}
        />
                    
                    
        <span className={`drop_down_icn ${pclass}`}></span>
        {showDropDown && <div className={`drop_down_option_container ${pclass}`}>
        { LanguageList.map((language_info,index)=>{
                return  <div key={index} className='option_container' onClick={()=>{ 
                
                    setSelectedLanguage(language_info.name)
                    setSelectedLanguageId(language_info.id)
                    }}>
                <div className='dropdown_option'>{language_info.name}</div>
            </div>
            })
        }
        </div>}
    </div>
  );
}

export default LanguageSelector;
