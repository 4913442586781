import { useNavigate } from "react-router-dom";
import LanguageSelector from "./_LanguageSelector";


export default function SubFooter(){

    const navigate = useNavigate()

    return (
        <div className="content_footer_wrapper cf_two">
          <div className="content_footer_container cf_two">
            {/* <div className="subfooter_container"> */}
            <div className="sub_footer">
                <footer className="content_footer_two">
                    <div className="content_footer_two__left">
                        <div className="content_footer_two__column">
                            <ul>    
                                <li style={{cursor: 'default'}}>© 2024 Colēgia LLC.</li>
                                <li>Cookie Policy</li>
                                <li>CA Privacy Notice</li>
                            </ul>
                        
                        </div>
                        <div className="content_footer_two__column">
                        <ul>   
                            <li><a href="/terms">Terms & Conditions</a></li>
                            <li>Sitemap</li>
                        </ul>
                        
                        </div>
                        <div className="content_footer_two__column">
                            <ul>
                                <li><a href="/privacy">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="content_footer_two__right">
                        <div className="content_footer_two__icons">
                            <div className="icn facebook"></div>
                            <div className="icn instagram" onClick={()=>{window.open('https://www.instagram.com/alaiamind/?igsh=MWJvdzB5aDV5anMzdA%3D%3D','_blank')}}></div>
                        </div>
                        <div className="content_footer_two__language">
                            <LanguageSelector/>
                        </div>
                    </div>
                </footer>
                </div>
                <div className="resposive_sub_footer">
                <footer className="content_footer_two">
                    <div className="content_footer_two__left">
                    <div className="content_footer_two__column">
                            <ul>   
                                <li><a href="/terms">Terms & Conditions</a></li>
                                <li>Sitemap</li>
                                <li><a href="/privacy">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div className="content_footer_two__column"> 
                            <ul>    
                                <li style={{cursor: 'default'}}>© 2024 Colēgia LLC.</li>
                                <li>Cookie Policy</li>
                                <li>CA Privacy Notice</li>
                            </ul>
                        </div>
                    </div>
                    <div className="content_footer_two__right">
                        <div className="content_footer_two__icons">
                            <div className="icn facebook"></div>
                            <div className="icn instagram" onClick={()=>{window.open('https://www.instagram.com/alaiamind/?igsh=MWJvdzB5aDV5anMzdA%3D%3D','_blank')}}></div>
                        </div>
                        <div className="content_footer_two__language">
                            <LanguageSelector/>
                        </div>
                    </div>
                </footer>
                </div>
            {/* </div> */}
          </div>
        </div>
      );
}