import { useState, useEffect, createContext, useContext } from 'react';
import { checksession } from './Events';
import { DateTime } from 'luxon';
import { timestampToRuby } from './helpers';
import { saveUserData, getUserData, clearUserData } from './helpers';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SessionContext = createContext({ isLoggedIn: false });
const useSession = () => useContext(SessionContext);
const SessionProvider = ({ children }) => {
  
  const [user, setUser] = useState();
  const userInfo = getUserData();
  const navigate = useNavigate();

  useEffect(() => {
     if ( Object.keys(userInfo).length === 0){
        // let date = new Date
        // let sess_end_time = timestampToRuby(DateTime.local().set({ year: parseInt(date.getFullYear()), month: parseInt(date.getMonth()), day: parseInt(date.getDate()), hour: parseInt(date.getHours()), minute: parseInt(date.getMinutes()), second: parseInt(date.getSeconds())}).ts);
        //   }
      handleLogin();
     }
     else{
        const userData = getUserData();
        const url_string = new URL(window.location.href)
        const login = url_string.searchParams.get('login')
        if(login){
          window.history.pushState(null, null, window.location.pathname);
        }
        if(((userData.user_info.accessed_at + (30*60) ) <  (Math.floor(new Date().getTime() / 1000)))|| login == 'true'){
          handleLogin();
        }
        else{
          setUser(userData)
        }
       
     }
    
  }, []);

  
  function find_type(url){
        if(url){
            if(url.indexOf('view') > -1) 
                return '';
            if(url.indexOf('reflect') > -1) 
                return 'reflect';
            else if(url.indexOf('activity') > -1)
                return 'activity';
            else if(url.indexOf('learn') > -1){
                if(url.indexOf('admin') > -1) return 'admin-learn';
                else return 'learn';
            }
            else return 'mindfulness'
        }
        else return ''
    }

  function handleLogin() {
    
    let payload = {
       Request:{
        Client:{
            platform: 'web'
        }
      }
    }
     checksession(payload).then((res)=>{
        if (res){
        if(res.data.session.status){
          const data = res.data.session
          const user_info = data.user_data
          const userData = {
            isLoggedIn: data.status,
            user_info
          };
  
          // if ( Object.keys(userInfo).length === 0){
            saveUserData(userData);
          // }
          setUser(userData);

          let redirect_url = localStorage.getItem('alaia_redirect_url');

          if (window.location.href.indexOf("admin") > -1) {
            redirect_url = null
          }

          if (redirect_url==null) {
            window.location.reload();  
          } else {
            localStorage.removeItem('alaia_redirect_url');
            localStorage.setItem('usage_tracking',JSON.stringify({
              url: redirect_url,
              usage_type: find_type(redirect_url),
              start_time: Date.now(),
           }));
         
            navigate(redirect_url.split(process.env.REACT_APP_BASE_URL)[1]);    
          }          
        }
        else{
         handleLogout();
        }
      }
        console.log(res)
       })
      .catch((error) => console.log('Error', error));
  }

  function handleLogout() {
    
    clearUserData();
    window.location.href = `${process.env.REACT_APP_COLEGIA_REDIRECT_PATH}`;
    
  }

  return (
    <SessionContext.Provider value={{ ...user, updateUser: setUser, logout: handleLogout}}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, useSession };
