import '../../assets/stylesheets/shared/loader.scss';
import { useState, useEffect } from 'react';

function CustomExerciseLoader() {
    const [timer, setTimer] = useState(0);
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [timerDuration, setTimerDuration] = useState(0);

    useEffect(() => {
        const updateTimerDuration = () => {
            const newTimerDuration = parseInt(document.getElementById('alaia_loader_custom_exercise').dataset.timerDuration || 0);
            setTimerDuration(newTimerDuration);
            // Set the timer to the initial duration when the duration is updated
            setTimer(newTimerDuration);
        };

        updateTimerDuration();

        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                    const displayStyle = mutation.target.style.display;
                    setIsLoaderVisible(displayStyle !== 'none');
                }
            }
            updateTimerDuration();
        });

        observer.observe(document.getElementById('alaia_loader_custom_exercise'), { attributes: true });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        let timerInterval;
        if (isLoaderVisible) {
            timerInterval = setInterval(() => {
                setTimer((prevTimer) => (prevTimer === 0 ? timerDuration : prevTimer - 1));
            }, 1000);
        } else {
            clearInterval(timerInterval);
            setTimer(timerDuration);
        }

        return () => clearInterval(timerInterval);
    }, [isLoaderVisible, timerDuration]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    return (
        <div className='alaia_loader_wrapper' id='alaia_loader_custom_exercise'>
            <div className='alaia_loader_container'>
                <div className='alaia_loader custom'>
                    {/* Display timer only when loader is visible or when timer is greater than 0 */}
                    {(isLoaderVisible || timer > 0) && <span className='alaia_loader_timer'>{formatTime(timer)}</span>}
                    <p className='loader_text custom'>
                        Please wait!
                        <br />
                        Do not refresh or close your <br/>
                        browser
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CustomExerciseLoader;
