import React from 'react'
import '../../assets/stylesheets/shared/nav_menu.scss'

export default function NavHeader(){
 
    return(
        <header className='nav_header_wrapper' id='the_header'>
            <span className='nav_menu_mask' onClick={() => document.getElementById('the_header').classList.remove('show_responsive')} />
            <div className='nav_header_container'>
                <span className='icon_menu' onClick={() => document.getElementById('the_header').classList.toggle('show_responsive')} />
                <div className='nav_logo_container'>
                </div>
            </div>
        </header>
    )
}