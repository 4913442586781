import {  Outlet, useNavigate } from 'react-router-dom';
import {  getUserData, saveUserData } from './helpers';
import { checksession } from './Events';
import { useEffect, useState } from 'react';
import { useSession } from './SessionProvider';
import { getSessionStatus, validateAdminSession } from './Validation';
import '../App.scss'
export default function AdminRoutes() {
 
  const { isLoggedIn, user_info } = useSession();
  const userInfo = getUserData().user_info
  const [sessStatusFlag, setSessStatusFlag] = useState(false)
  const navigate = useNavigate()

  async function handlelogin(){
    let payload = {
      Request:{
        Client:{
          platform: 'web'
        }
      }
    }
   console.log('payload',payload)
    checksession(payload).then((res)=>{
       if (res){
        
        if(res.data?.session.status){
          const data = res.data.session
          const user_info = data.user_data
          const userData = {
            isLoggedIn: data.status,
            user_info
          };
          if(user_info.session_type !== 'admin_session'){
            navigate('/home')
          }
           saveUserData(userData);
           setSessStatusFlag(true)
        }
        else{
          navigate('/login')
        }
      }
    })
  }

  useEffect(()=>{

    const url_string = new URL(window.location.href)
    const login = url_string.searchParams.get('login')

    if(login){
      window.history.pushState(null, null, window.location.pathname);
    }

    if(!getSessionStatus() || login == 'true'){
      handlelogin();
    }
    else{

      if(validateAdminSession()){
        setSessStatusFlag(true)
        if(userInfo?.session_type !== 'admin_session'){
          navigate('/home')
        }
      }
      else{
        handlelogin();
      }
    }
  },[])

  return <>
     {sessStatusFlag && userInfo?.session_type === 'admin_session' && <div className={`page_container`} id='app_main_page_container'>
          <div className='main_wrapper'>
            <div className='main_container'>
              <Outlet/>
            </div>
        </div>
      </div>}
  </>
  
  

}