import { Navigate, Outlet, } from 'react-router-dom';
import { getUserData } from './helpers';
import { useEffect } from 'react';

export default function TeacherRoutes({ rpath }) {
  const UserInfo = getUserData();
  const isLoggedIn = UserInfo.isLoggedIn
  const permissions = []
  
  if(Object.keys(UserInfo?.user_info || {}).length > 0){
    const colegia_permissions = UserInfo.user_info.permissions || []
    if(colegia_permissions.length > 0){
      colegia_permissions.forEach(function(obj){
          permissions.push(...obj.permissions)
      })
    }
  }

  function changeBackgroundcolor(){
    if (window.location.href.indexOf("teacher") > -1) {
      document.body.style.backgroundColor = '#FFFFFF'
    }
  }

  useEffect(()=>{
    if(document.readyState != 'loading'){
      changeBackgroundcolor();
    }
    else{
      document.addEventListener('DOMContentLoaded', changeBackgroundcolor);
      return () => {
          document.removeEventListener('DOMContentLoaded', changeBackgroundcolor);
      };
    }

  },[])

 
  return (
    (
      isLoggedIn && (permissions.includes('learn') || permissions.includes('demo_learn'))  ?
        <Outlet /> : <Navigate to={rpath}/>
    )
  )
}