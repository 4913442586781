import { Route,Navigate, Outlet, useLocation } from 'react-router-dom';

export default function StateRoute({ rpath }) {
  const {state} = useLocation();
 
  return (
    (
      state ?
        <Outlet /> : <Navigate to={rpath}/>
    )
  )
}