import { clearUserData } from './helpers'
import axios from 'axios'

const axiosClient = axios.create()
// const getCSRFToken = async () => {
//   const response = await axiosClient.get('getCSRFToken');
 
// }
// axiosClient.defaults.headers['X-CSRF-Token'] = '1234'
 axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
    Accept: 'application/json'
    // 'X-CSRF-Token': response.data.mwsResponse.csrf_token
  }
axiosClient.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`


axiosClient.defaults.withCredentials = true
// getCSRFToken();
axiosClient.interceptors.response.use(function (response) {
 
  if (response.data.session != null && response.data.session.status == false){
     clearUserData();
     if(!(window.location.href.indexOf('admin') > -1)){
      localStorage.setItem('alaia_redirect_url',window.location.href)
     }
     window.location.href = `${process.env.REACT_APP_REDIRECT_PATH}`;
  }
  else{
  return response
  }
 
}, function (error) {
  if(error.response){
  if (error.response.status === 401) {
    // clearSession()
   
  }
}
  return Promise.reject(error)
})

export default axiosClient
