import { checksession } from "./Events";
import { getUserData } from "./helpers";

const checkErrorResponse = (err) => {
  if (err.response) {
    return JSON.stringify(err.response.data.error);
  } else {
    return "We're sorry, but something went wrong (500)"
  }
}

export const showCallError = (err, setErr, setErrFlag) => {
  err = checkErrorResponse(err);
  // console.log(err);
  if(err == null){
  err = "We're sorry, but something went wrong (500)";
  }
  setErr(err);
  setErrFlag(true);
}

export const validateTime = (time) =>{
  if(time==undefined)
  return[time, false]

  var timeregex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
  let hr=0;
  let min=0;
  let status = false
  if(time.split(':').length == 2){
    hr = time.split(':')[0]
    min = time.split(':')[1]
    hr = String(hr).padStart(2, 0)
    if(min.length > 3){
      if(min[2] != ' '){
        min = min.slice(0,2)+' '+min.slice(2,4)
      }
    }
    else{
      return [time, status]
    }
  }
  else{
    return [time, status]
  }
  let formatted_time = `${hr}:${min}`
  return [formatted_time, timeregex.test(formatted_time)]
}


export const RemoveLastDirectoryPartOf = (the_url) =>{
    var the_arr = the_url.split('/');
    the_arr.pop();
    return( the_arr.join('/') );
}

export const clearSession = () => {
  localStorage.clear();
}

export const isSessionExpired = async () => {
 
  try {
    let payload = {
      Request:{
       Client:{
           platform: 'web'
       }
     }
   }
    checksession(payload).then((res)=>{
       if (res.data.session && res.data.session !== '') clearSession();
    })
  } catch (error) {
    console.error('Error checking session status:', error);
  }
  
};

export const getSessionStatus = () =>{
  return getUserData()?.isLoggedIn
}

export const validateSession = () =>{
 
  const last_accessed_time = getUserData()?.user_info.accessed_at 

  if(((last_accessed_time + (30*60) ) <  (Math.floor(new Date().getTime() / 1000)))){
    return false
  }
  return true
}

export const validateAdminSession = () =>{
 
  const last_accessed_time = getUserData()?.user_info.admin_accessed_at 
  console.log(last_accessed_time)
  if(((last_accessed_time + (30*60) ) <  (Math.floor(new Date().getTime() / 1000)))){
    return false
  }
  return true
}

export const showValidationError = (vArray) => {
  
  vArray.forEach((vItem) => {
    if (!vItem.vHash.status) {
      showInputError(vItem.node, vItem.vHash.msg);
    
    }
  });
}

export const getValidationStatus = (vArray) => {
  let vCheckStatus = true;
  vArray.forEach((vItem) => {
    vCheckStatus = !vItem.vHash.status ? false : vCheckStatus;
    hideInputError(vItem.node);
  });

  return vCheckStatus;
}


export const showInputError = (node, error) => {
  if (node.current) {
    node.current.classList.add('has_error');
    node.current.querySelectorAll('.input_error span').forEach((entity) => {
      entity.innerHTML = error;
    });
  }
}

export const hideInputError = (node) => {
  if (node.current) {
    node.current.classList.remove('has_error');
    node.current.querySelector('.input_error span').innerHTML = '';
  }
}

export const validateRequired = (str, message = undefined) => {
  if(str == null){
    return { status: false, msg: message ? message : '*Required Field' };
  }
  
  str = str.toString().trim();
  if (!str && str == '') {
    return { status: false, msg: message ? message : '*Required Field' };
  }

  return { status: true };
}