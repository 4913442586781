import '../../assets/stylesheets/shared/loader.scss'
import gifLoader from '../../assets/images/loader.gif'

function Loader() {

  return (
    <div className='alaia_loader_wrapper' id='alaia_loader'>
      <div className='alaia_loader_container'>
        <div className='alaia_loader'>
          <span className='loader_icon'>
            <img src={gifLoader} alt='Loading...' width='120' height='120' />
          </span>
          <p className='loader_text'>
            Please wait!
            <br />
            Do not refresh or close your browser
          </p>
        </div>
      </div>
    </div>
  )
}

export default Loader;
