import {  Outlet, useNavigate } from 'react-router-dom';
import {  getUserData, saveUserData } from './helpers';
import { checksession } from './Events';
import { useEffect, useState } from 'react';
import { useSession } from './SessionProvider';
import { getSessionStatus, validateSession } from './Validation';
import NavMenu from '../Components/shared/NavMenu';
import '../App.scss'
export default function AppRoutes() {
 
  const { isLoggedIn, user_info } = useSession();
  const userInfo = getUserData().user_info
  const [sessStatusFlag, setSessStatusFlag] = useState(false)
  const navigate = useNavigate()

  async function handlelogin(){
    let payload = {
      Request:{
        Client:{
            platform: 'web'
        }
      }
    }
   console.log('payload',payload)
    await checksession(payload).then((res)=>{
       if (res){
        
        if(res.data?.session.status){
          const data = res.data.session
          const user_info = data.user_data
          let element = document.getElementById('alaia_main_body_id');
          if (element && element.style) {
              element.style.backgroundImage = 'none';
          }
          if(user_info.session_type === 'admin_session'){
            window.open(`https://${window.location.hostname}/app/admin?lang=en`,'_self')
          }
          const userData = {
            isLoggedIn: data.status,
            user_info
          };
           saveUserData(userData);
           setSessStatusFlag(true)
        }
        else{
          navigate('/login')
        }
      }
    }
    )
  }

  function find_type(url){
    if(url){
        if(url.indexOf('view') > -1) 
            return '';
        if(url.indexOf('reflect') > -1) 
            return 'reflect';
        else if(url.indexOf('activity') > -1)
            return 'activity';
        else if(url.indexOf('learn') > -1){
            if(url.indexOf('admin') > -1) return 'admin-learn';
            else return 'learn';
        }
        else return 'mindfulness'
    }
    else return ''
  }

  useEffect(()=>{
    const url_string = new URL(window.location.href)
    const login = url_string.searchParams.get('login')
    if(login){
      window.history.pushState(null, null, window.location.pathname);
    }
    if(!getSessionStatus() || login == 'true'){
      handlelogin();
    }
    else{

      if(validateSession()){
        setSessStatusFlag(true)
        let element = document.getElementById('alaia_main_body_id');
        if (element && element.style) {
            element.style.backgroundImage = 'none';
        }
       
        if(userInfo?.session_type === 'admin_session'){
          window.open(`https://${window.location.hostname}/app/admin?lang=en`,'_self')
        }
        let redirect_url = localStorage.getItem('alaia_redirect_url');
        
        if(redirect_url){
          localStorage.removeItem('alaia_redirect_url');
          localStorage.setItem('usage_tracking',JSON.stringify({
            url: redirect_url,
            usage_type: find_type(redirect_url),
            start_time: Date.now(),
         }));
          navigate(redirect_url.match(/\/\/[^\/]+\/(.*)$/)[1]);    
        }
      }
      else{
        handlelogin();
      }
     
    }
  },[])

  return <>
     {sessStatusFlag && userInfo?.session_type!=='admin_session' && <div className={`page_container ${userInfo?.session_type!='admin_session' &&  !(((window.location.href.indexOf("preview") > -1) && (window.location.href.indexOf("learn"))) || (window.location.href.indexOf("view") > -1) ) ? 'main' : ''}`} id='app_main_page_container'>
        { userInfo?.session_type!='admin_session' &&  !(((window.location.href.indexOf("preview") > -1) && (window.location.href.indexOf("learn"))) || (window.location.href.indexOf("view") > -1) ) && <NavMenu/>}
          <div className='main_wrapper'>
            <div className='main_container'>
              <Outlet/>
            </div>
        </div>
      </div>}
  </>
  
  

}